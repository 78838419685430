import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCollectives(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}collectives`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCollective(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}collectives/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCollective(ctx, categoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}collectives`, categoryData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCollective(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}collectives/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCollective(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${axiosDefaultConfig.backendEndPoint}collectives/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrganisations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}companies`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
